<template>
  <v-card class="px-8 py-4">
    <v-row>
      <v-col cols="12" sm="6" lg="5" class="py-4 px-4">
        <v-layout align-center class="text-sm-left" fill-height>
          <img
            class="logoPasswrd"
            v-if="logo"
            :src="serverUrl + '/images/' + logo + '?token=' + userToken"
          />
          <img
            v-else
            class="logoPasswrd"
            :src="getLogo"
            @error="failedImage = true"
          />
        </v-layout>
      </v-col>
      <v-col cols="12" sm="6" lg="7" class="py-4 px-4">
        <languageSelector :addContainer="true" />

        <br />

        <v-form v-model="valid" ref="form" lazy-validation>
          <v-text-field
            :label="$t('Email')"
            v-model="email"
            required
            validate-on-blur
            :rules="getRules('notEmptyEmailRule')"
          ></v-text-field>
          <v-row no-gutters class="mt-4">
            <v-btn @click="$router.go(-1)" class="mt-2">
              <v-icon left> mdi-arrow-left </v-icon>{{ $t("Cancel") }}</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              @click="sendResetLink()"
              :loading="loading"
              color="primary"
              class="text-right mt-2"
              >{{ $t("Send reset link") }}</v-btn
            >
          </v-row>

          <errorHandeling
            v-if="success.length > 0"
            :buttons="[
              {
                isText: true,
                functionName: 'clearError',
                text: 'Close',
              },
            ]"
            :snackbarText="success"
            snackbarColor="success"
            snackbarTimout="-1"
            snackbarIcon="mdi-check"
            @clearError="success = ''"
          />
          <errorHandeling
            v-if="error"
            :snackbarText="error"
            :buttons="[
              {
                isText: true,
                functionName: 'clearError',
                text: 'Close',
              },
            ]"
            snackbarColor="error"
            snackbarTimout="-1"
            snackbarIcon="mdi-alert"
            @clearError="$store.dispatch('resetError')"
          />
        </v-form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import validation from "../../validation";

export default {
  data() {
    return {
      logo: this.$config.logoPos,

      serverUrl: this.$helpers.getServerUrl(),
      success: "",
      email: "",
      loading: false,
      userToken: this.$auth.userToken(),
      valid: true,
      failedImage: false,
    };
  },
  computed: {
    ...mapGetters({
      error: "error",
    }),
    getLogo: {
      get() {
        return this.failedImage
          ? this.serverUrl +
              "/images/" +
              "logo-pos.png" +
              "?token=" +
              this.userToken +
              "&v=" +
              this.getDate()
          : this.serverUrl +
              "/images/" +
              "logo-pos.svg" +
              "?token=" +
              this.userToken +
              "&v=" +
              this.getDate();
      },
    },
  },
  methods: {
    getRules(rule) {
      return validation.getValidation(rule);
    },
    sendResetLink() {
      this.success = "";

      if (this.$refs.form.validate()) {
        this.loading = true;

        this.$request.post("/sendresetlink", this.email, (res) => {
          this.loading = false;

          if (res.success) {
            this.success = res.message;
            this.$store.dispatch("resetError");
          } else {
            this.$store.dispatch("setError", res);
          }
        });
      }
    },
    getDate() {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();

      return mm + dd + yyyy;
    },
  },
};
</script>

<style scoped>
.logoPasswrd {
  max-width: 100%;
  width: 90%;
  margin: auto;
}
</style>
