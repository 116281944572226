var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"px-8 py-4"},[_c('v-row',[_c('v-col',{staticClass:"py-4 px-4",attrs:{"cols":"12","sm":"6","lg":"5"}},[_c('v-layout',{staticClass:"text-sm-left",attrs:{"align-center":"","fill-height":""}},[(_vm.logo)?_c('img',{staticClass:"logoPasswrd",attrs:{"src":_vm.serverUrl + '/images/' + _vm.logo + '?token=' + _vm.userToken}}):_c('img',{staticClass:"logoPasswrd",attrs:{"src":_vm.getLogo},on:{"error":function($event){_vm.failedImage = true}}})])],1),_c('v-col',{staticClass:"py-4 px-4",attrs:{"cols":"12","sm":"6","lg":"7"}},[_c('languageSelector',{attrs:{"addContainer":true}}),_c('br'),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"label":_vm.$t('Email'),"required":"","validate-on-blur":"","rules":_vm.getRules('notEmptyEmailRule')},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":""}},[_c('v-btn',{staticClass:"mt-2",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-arrow-left ")]),_vm._v(_vm._s(_vm.$t("Cancel")))],1),_c('v-spacer'),_c('v-btn',{staticClass:"text-right mt-2",attrs:{"loading":_vm.loading,"color":"primary"},on:{"click":function($event){return _vm.sendResetLink()}}},[_vm._v(_vm._s(_vm.$t("Send reset link")))])],1),(_vm.success.length > 0)?_c('errorHandeling',{attrs:{"buttons":[
            {
              isText: true,
              functionName: 'clearError',
              text: 'Close',
            },
          ],"snackbarText":_vm.success,"snackbarColor":"success","snackbarTimout":"-1","snackbarIcon":"mdi-check"},on:{"clearError":function($event){_vm.success = ''}}}):_vm._e(),(_vm.error)?_c('errorHandeling',{attrs:{"snackbarText":_vm.error,"buttons":[
            {
              isText: true,
              functionName: 'clearError',
              text: 'Close',
            },
          ],"snackbarColor":"error","snackbarTimout":"-1","snackbarIcon":"mdi-alert"},on:{"clearError":function($event){return _vm.$store.dispatch('resetError')}}}):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }